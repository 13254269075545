import { InsuranceOfferModel } from "./InsuranceOfferModel";
import { InsuranceOptionModel } from "./InsuranceOptionModel";

export class InsuranceOffersSummary {

    insuranceOffers: InsuranceOfferModel[];
    isRequestedFlightAreaRestricted: boolean;
    insuranceOptions: InsuranceOptionModel[]
  
    constructor(insuranceOffers: InsuranceOfferModel[], options: InsuranceOptionModel[], isRequestedFlightAreaRestricted: boolean) {
      this.insuranceOffers = insuranceOffers;
      this.isRequestedFlightAreaRestricted = isRequestedFlightAreaRestricted;
      this.insuranceOptions = options
    }
  }
  