import ApiService, { HTTPMethod } from "./api.core";
import { Transformer } from "../models/Transformer";
import { isToday, getAiWithoutId, equivalentAdditionalInsured, removeDuplicateAdditionalInsured, isTodayBreakdown, isCanada } from "../services/utils";
import { FirebaseService, FirebaseConfigKey } from "../services/FirebaseService";
import { COUNTRIES, NUMBER_OF_DRONES_QUESTION_ANSWERS } from "../Constants";
import { SessionService } from "../services/SessionService";
import { COVERAGE_TYPES } from "../models/insurance-models/CoverageType";
import { WorldWideOption } from "../models/insurance-models/WorldWideOption";


class InsuranceApi {

    async loadOffers(subscriptionStartDate: Date, maximumUasInAir = 1, coverageType = COVERAGE_TYPES.COMBINED, drones=[],
         equipment=[], operators=[], personalInjuryCoverage = 0, medicalExpense = 0,
          worldWideCoverage = { territorialLimits: WorldWideOption.Default, territorialLimitsDescription: '' }, 
          indoorCoverage = { included: false, description: '' },
          numberOfDronesOperate = 0, fleetInformation, liabilitySpecialPrice = null) {

        let body = (!subscriptionStartDate || isTodayBreakdown(subscriptionStartDate.getUTCDate(), subscriptionStartDate.getUTCMonth(), subscriptionStartDate.getUTCFullYear())) ? {} : {
            "start_time": subscriptionStartDate.getTime()
        };

        if(fleetInformation) {
            body.fleet_information = {
                size: fleetInformation.fleetSize,
                intended_use: fleetInformation.fleetIntendedUse,
                is_publishable: fleetInformation.fleetIsPublishable,
                is_procedures_control: fleetInformation.fleetProceduresControl,
                yearly_size: fleetInformation.yearlySize
            }
        }

        if(indoorCoverage.included) {
            body.indoor_coverage = indoorCoverage.included
            body.indoor_coverage_description = indoorCoverage.description
        }

        if(liabilitySpecialPrice != null) {
            body.liability_special_price = liabilitySpecialPrice.custom_premium
        }

        if(FirebaseService.getValue(FirebaseConfigKey.COUNTRY) == COUNTRIES.UK) {
            body.maximum_uas_in_air = maximumUasInAir 
            body.coverage_type = coverageType
            body.operators = operators
        }

        if(!SessionService.isLoggedIn()) {
            body.drones = drones
            body.equipment = equipment
            body.operators = operators
        }

        body.medical_expense_limit = medicalExpense
        body.personal_injury_limit = personalInjuryCoverage
        body.territorial_limits_description = worldWideCoverage.territorialLimitsDescription
        body.territorial_limits = worldWideCoverage.territorialLimits
        body.number_of_drones_operate = numberOfDronesOperate;
        body.carrier = 'Global';

        let res = await ApiService.fetch("subscriptions/offers", HTTPMethod.POST, body);

        if (res.ok) {  
            res.monthlyOffers = Transformer.parseOffers(res.data["offers"])
            res.annualOffers = Transformer.parseOffers(res.data["annual_offers"]);
        }
        return res; 
    }

    async isGlobalRatings(){
        const res = await ApiService.fetch('subscriptions/IsGlobalRatings', HTTPMethod.GET);
        return res
    }

    async generateUpgradeUrl(userName) {
        let res = await ApiService.fetch('insurances/generate-upgrade-url', HTTPMethod.POST, {"user_name": userName}, true, true);
        return res
    }

    async submitAviationForm(forminformation) {
        let res = await ApiService.fetch('aviationformsubmit', HTTPMethod.POST, forminformation)
        return res
    }

    async getActiveInsurances() {
        let res = await ApiService.fetch("insurances/me/active");
        if (res.ok && res.data && res.data.length > 0) {
            let activeInsurance = Transformer.parseActiveInsuranceModel(res.data[0])
            res.parsedData = activeInsurance;
        }
        return res;
    }


    async purchaseInsurance(optionId, additionalInsured, autoRenew, externalActorId, paymentMethod, utm_campaign, utm_medium, utm_source, utm_term, utm_content ) {
        let body = {}; 
        body.offer_id = optionId;
        body.auto_renew = !!autoRenew;
        body.payment_method = paymentMethod
        body.carrier = 'Global'
        body.utm_campaign=utm_campaign
        body.utm_content=utm_content
        body.utm_medium=utm_medium
        body.utm_source=utm_source
        body.utm_term=utm_term
    
        if(externalActorId) {
            body.external_actor_id = externalActorId
        }

        if (additionalInsured) {
          body.additional_insureds = removeDuplicateAdditionalInsured(additionalInsured.map(q => getAiWithoutId(q)));
        }
        
        let res = await ApiService.fetch("insurances/", HTTPMethod.POST, body);
        
        if (res.ok) {
          let activeInsurance = Transformer.parseActiveInsuranceModel(res.data.insurance)
          res.parsedData = activeInsurance;
        }
        return res;
    }

    async sendAQuote(insurancePurchaseContext, offersContext, emails, companyName) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
            emails: emails, 
            name: companyName, 
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits: offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            liability_special_price: offersContext.liabilitySpecialPrice.custom_premium,
            indoor_coverage: offersContext.indoorCoverage.included,
            indoor_coverage_description: offersContext.indoorCoverage.description,
            number_of_drones_text: offersContext.numberOfDronesText,
            number_of_drones_operate: offersContext.numberOfDronesOperate,
            fleet_details: {
                size: offersContext.fleetInformation.fleetSize,
                intended_use: offersContext.fleetInformation.fleetIntendedUse,
                is_publishable: offersContext.fleetInformation.fleetIsPublishable,
                is_procedures_control: offersContext.fleetInformation.fleetProceduresControl,
                yearly_size: offersContext.fleetInformation.yearlySize
            },
            carrier: "Global"
        }
        if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
            body["start_time"] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/quote', HTTPMethod.POST, body)
    }

    async saveQuoteState(insurancePurchaseContext, offersContext) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits: offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            liability_special_price: offersContext.liabilitySpecialPrice.custom_premium,
            indoor_coverage: offersContext.indoorCoverage.included,
            indoor_coverage_description: offersContext.indoorCoverage.description
        }
        if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
            body["start_time"] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/purchaseState', HTTPMethod.POST, body)
    }

    async referQuote(insurancePurchaseContext, offersContext) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits: offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            indoor_coverage: offersContext.indoorCoverage.included,
            indoor_coverage_description: offersContext.indoorCoverage.description
        }
        if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
            body["start_time"] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/refer-quote', HTTPMethod.POST, body)
    }

    async getPolicyHistory(insuranceId){
        const res = await ApiService.fetch(`insurances/history/${insuranceId}`, HTTPMethod.GET);
        return res
    }
    
}

export default new InsuranceApi()