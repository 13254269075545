

import React from "react"
import { Button } from "@material-ui/core"
import './OrangeButton.scss'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { EventEmitterService, EventKey } from "../../../services/EventEmitterService";

export const OrangeButton = (props) => {

    return (
        <Button {...props} variant="contained" disableElevation className={`orange-button ${props.className}`}>{props.children}</Button>
    )
}