import { LiabilityExtension } from "./LiabilityExtension"
import { PhysicalDamageExtension } from "./PhysicalDamageExtension"
import { InsuranceStatus } from "./InsuranceStatus"
import { Drone } from "../hull-models/Drone"
import { Equipment } from "../hull-models/Equipment"
import { AdditionalInsuredModel } from "../user-models/AdditionalInsuredModel"
import { AdditionalInsuredEndorsementExtension } from "./AdditionalInsuredEndorsementExtension"
import { Operator } from "./Operator"
import { InsuranceTypes } from "./InsuranceBillingPeriodModel"
import { ActiveInsuranceSubscription } from './ActiveInsuranceSubscription'

export class ActiveInsuranceModel {
    _certificateUrl: string
    policyUrl: string
    insuranceId: string
    numberOfDronesOperate: number
    namedInsured: any
    summary: ?string[]
    flightArea: any
    carrier: string
    timeLeftSeconds: number
    autoRenew: boolean
    renewable: boolean
    policyNumber: number
    _liabilityLimit: number
    liabilityLimitPremium: number
    liabilityLimitDiscount: number
    isLiabilitySpecialPrice: boolean
    locationDescription: string
    _insuranceEnd: Date
    _insuranceStart: Date
    dateAdded: Date
    insuranceType: string
    additionalInsuredList: AdditionalInsuredModel[]
    insuredDrones: Drone[]
    insuredEquipment: Equipment[]
    finalPrice: number
    dronePremium: number
    equipmentPremium: number
    insuranceMidterm:any
    cancelRefund:number
    cancelDateUtc:Date
    indoorCoverageIncluded: Boolean
    liabilityLimitExtensions: LiabilityExtension[]
    physicalDamageExtensions: PhysicalDamageExtension[]
    additionalInsuredExtensions: AdditionalInsuredEndorsementExtension[]
    isSuspended: Boolean
    isSuspendable: Boolean
    suspensionStartTime: Date
    suspensionEndTime: Date
    operators: Operator[]
    subscription: ActiveInsuranceSubscription
    personalInjuryLimitPremium: Number
    personalInjuryLimit: Number
    medicalExpenseLimitPremium: Number
    medicalExpenseLimit: Number
    territorialLimits: String
    territorialLimitsPremium: Number
    policyStatus: String
    failedActivation: String
    

    get certificateUrl() {
      return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].certificateUrl : this._certificateUrl
    }

    set certificateUrl(val) {
      this._certificateUrl = val
    }

    get liabilityLimit() {   
      return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].liabilityLimit : this._liabilityLimit
    }

    get totalLiabilityPremium() {
      return this.liabilityLimitPremium + (this.hasLiabilityExtentions()? this.liabilityLimitExtensions.reduce((res, ext) => res + ext.purchaseDetails.price, 0) : 0);
    }

    set liabilityLimit(val) {
      this._liabilityLimit = val
    }
  
    set insuranceStart(val) {
      this._insuranceStart = val
    }
  
    get insuranceStart(): Date {
      return this._insuranceStart;
      // return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].validFromUtc : this._insuranceStart
    }
  
    set insuranceEnd(val) {
      this._insuranceEnd = val
    }
  
    get insuranceEnd(): Date {
      return this._insuranceEnd;
      // return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].validUntilUtc : this._insuranceEnd
    }
  
    isSubscription() {
      return this.insuranceType == InsuranceTypes.MONTHLY || this.insuranceType == InsuranceTypes.YEARLY
    }
  
    hasLiabilityExtentions() {
      return this.liabilityLimitExtensions && this.liabilityLimitExtensions.length > 0
    }

    isActive() {
      return this.status === 'Active' 
    }

    isScheduled() {
      return this.status === 'Scheduled' || this.status ==='UpcomingRenewal'
    }

    isActiveOrSchedule() {
      return this.isActive() || this.isScheduled() || this.isSuspended
    }

    isCanceled(){
      return this.status === 'Canceled'
    }

    isExpired(){
      return this.status === 'Expired'
    }

    getStatus() {
      if (this.isActive())
        return InsuranceStatus.ACTIVE
      else if (this.isScheduled()) 
        return InsuranceStatus.SCHEDULED
      else if (this.isSuspended)
        return InsuranceStatus.SUSPENDED
      else if (this.isCanceled())
        return InsuranceStatus.CANCELED
      else 
        return InsuranceStatus.EXPIRED
    }
  } 