import { ApiResponseModel } from '../models/api/ApiResponseModel'
import { SKYWATCH_API_VERSION, VERSION } from '../Constants'
import { SessionService } from '../services/SessionService';
import { EventEmitterService, EventKey } from '../services/EventEmitterService';
import BrokerConfigService from '../services/BrokerConfigService';

export class HTTPMethod {
    static GET = "GET";
    static POST = "POST";
    static PATCH = "PATCH";
    static DELETE = "DELETE";
    static PUT = "PUT";
}

export default class ApiService {

    static getPartnerId() {
        var Id = "web";
        try {
          let pairs = window.location.search.slice(1).split("&");
          pairs.forEach(function(pair) {
            pair = pair.split("=");
            if (pair[0] === "partnerId") {
              Id = decodeURIComponent(pair[1] || "");
            }
          });
        } catch (e) {
          return Id;
        }
        return Id;
    }

    static async getResponseByType(response) {
      const contentType = response.headers.get('content-type')
        if (contentType && contentType.indexOf('application/octet-stream') !== -1) {
         return await response.blob()
        } else {
          return await response.json()
        } 
    }

    static async fetch(url, method, body, tojson=true, useBrokerToken=false) {
        let resp = new ApiResponseModel();
        let fullUrl = process.env.REACT_APP_SERVER_URL + url;
        if (!method) method = HTTPMethod.GET;
    
        let token = SessionService.getToken();
        
        if(useBrokerToken) {
          token = SessionService.getStoredToken()
        }
        
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.has('token') && window.location.pathname != '/changeEmail') token = urlParams.get('token')

        let partnerId = this.getPartnerId();
    
        let headers = {
          Accept: "application/json",
          Authorization: token ? "Bearer " + token : null,
          "skywatch-api-version": SKYWATCH_API_VERSION,
          "skywatch-api-partner": partnerId,
          "skywatch-app": "web",
          'skywatch-app-version': VERSION,
          'skywatch-sub-domain': BrokerConfigService.brokerName == 'skywatch'? '' : BrokerConfigService.brokerName
        };

        if(tojson) {
          headers = Object.assign(headers, {"Content-Type": "application/json"})
        }
    
        let requestParams = {
          method: method,
          headers: headers,
          body: tojson && body ? JSON.stringify(body) : body,
          mode: "cors" // no-co
        };
    
        try {
          let response = await fetch(fullUrl, requestParams);

          resp.ok = response.ok;
          resp.status = response.status;
          if (response.headers.get("content-length") != 0) {
            try {
               resp.data = await this.getResponseByType(response)
            } catch (e) {
              return resp;
            }
          }
          if (!resp.ok) {
            //FirebaseService.logEvent(FirebaseLogEvent.SERVER_ERROR, { "status_code": resp.status_code, "url": url,  "data": resp.parsedData});
          }
        }
        catch (e) {
          
          const delay = ms => new Promise(res => setTimeout(res, ms));
          await delay(1500);

          resp.ok = false;
          resp.status = 'Unknown';
        }
        
        if(resp.status == 401 && url != 'auth/logout') {
          EventEmitterService.dispatch(EventKey.USER_UNAUTHORIZED)
        }
        
        return resp;
      }

      static async uploadFile(url, file) {
        var form = new FormData();
        form.append("file", file, file.name);
    
        return ApiService.fetch(url, HTTPMethod.POST, form, false)
      }

      static async uploadFileXHR(url, form, files, onsuccess, onerror, progress) {
        let xhr = new XMLHttpRequest();
        xhr.open('POST', url);

        for(var i = 0; i < files.length; i++) {
          form.append("files", files[i]);
        }
        
        // track completion: both successful or not
        xhr.onloadend = function() {
          if (xhr.status == 200) {
            onsuccess && onsuccess()
          } else {
            onerror && onerror(xhr.status, xhr.response)
          }
        };
    
        xhr.upload.onprogress = function(event) {
          progress && progress(Math.ceil((event.loaded / event.total) * 100))
        };
        
        xhr.upload.onerror = function() {
          onerror && onerror(xhr.status, xhr.response)
        };
    
        xhr.onerror = function() {
          onerror && onerror(xhr.status, xhr.response)
        };
    
        xhr.send(form);
    
        return xhr
      }
}