
export default class QuoteModel {
    id: String
    isActive: Boolean
    dateAdded: Date
    lastUpdated: Date
    billingPeriod: String
    quotePrice: Number
    liability: Number
    partnershipId: Number
    actorUserId: Number
    quoteDocumentUrl: String
    purchaseQuoteUrl: String
    userProfileId: Number
    status: String
    isUserRegistered: Boolean
    name: String
    company: String
    email: String
    stateBase64: String
}

export class QuoteStateModel {
    stateBase64: String
}