


export class EquipmentType {
    name: String
    iconId: String
    iconGroupId: String
    subType: int

    getSvgName = () => {
        return this.name.toLocaleLowerCase().replace(' ', '-')
    }

    constructor(name='') {
        this.name = name
    }
}