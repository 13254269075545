export const VERSION = "v 2.0.0";

export const SKYWATCH_API_VERSION = 3;

export const NUMBER_OF_DRONES_QUESTION_ANSWERS = {
  0: {
    title: "Individual / Small Business ",
    value: "1-2 Drones",
    max_drones: 2,
  },
  1: {
    title: "Small Fleet",
    value: "3-5 Drones",
    max_drones: 5,
  },
  2: {
    title: "Large Fleet",
    value: "6 or more Drones",
    max_drones: 0,
  },
};

export const STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const PAYMENT_METHODS = {
  applePay: {
    name: "Apple pay",
  },
  credit: {
    name: "Credit/debit",
  },
  googlePay: {
    name: "Google pay",
  },
};

export const STATE_EXPIRY_TTL = 1000 * 60 * 60 * 24 * 2; // 2 days

export const THREE_WEEKS_IN_MS = 1000 * 60 * 60 * 24 * 7 * 3;

export const MAX_FUTURE_DATE_DAYS = 60;

export const PHONE_NUMBER_REGEX = /^[0-9*\+-]+$/;

export const COUNTRIES = { US: "US", CANADA: "Canada", UK: "UK" };

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const POBOX_REGEX =
  /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i;

export const WEIRD_GENERATED_GUID = "00000000-0000-0000-0000-000000000000";

export const HULL_MAX_LENGTH_SERIAL_NUMBER = 20;
export const HULL_MIN_LENGTH_SERIAL_NUMBER = 6;
export const UK_HULL_MIN_LENGTH_SERIAL_NUMBER = 1;
export const CA_POSTAL_CODE_REGEX = /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$/

export const US_ZIP_CODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/

export const SKYWATCH_GLOBAL_PORTAL_URL =
  "https://skywatch.aero.insure/dtm/application/uas/us/applicant-information?new=true";

  
export const fleetUses = [
  "Agriculture",
  "Commercial Photography/Videography",
  "Construction",
  "Education, Research & Development",
  "Events (Concerts / Sports / Weddings etc)",
  "Fire Fighting/Support",
  "Infrastructure Inspection & Support",
  "Instruction and Training",
  "Law Enforcement",
  "Mapping / geophysical",
  "Media / News Gathering",
  "Military",
  "Movie/TV Production",
  "Package delivery",
  "Private / Hobby",
  "Property Survey/Inspection/Real Estate",
  "Sales / Demo",
  "Search and Rescue",
  "Surveillance",
  "Wildlife / Conservation / Environmental",
];
