

export const InsuranceTypes = {
    MONTHLY: "Monthly",
    YEARLY: "Yearly",
    HOURLY: "Hourly",
  }
  
  export const InsuranceBillingPeriod = {
    MONTH: "Month",
    YEAR: "Year",
  }

  