
export class Action {

    data: any
    type: String

    constructor(type, data) {
        this.type = type
        this.data = data
    }
}