function toHex(x) {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
}

function hexToHsl(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    r /= 255;
    g /= 255;
    b /= 255;
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);

    return {h: h, s: s, l: l};
}

function hue2rgb (p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
};

function hslToHex(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}


class SColor {

    constructor(hue, sat, light, lightBackground=false, darkBackground=false) {
        // Settings
        // Play with these to change the types of colors generated
        this.minHue = 0;
        this.maxHue = 360;

        this.minSat = 75;
        this.maxSat = 100;

        this.minLight = 65;
        this.maxLight = 80;

        this.scaleLight = 15;

        // Darker colors for a light background
        if (lightBackground) {
            this.minLight = 90;
            this.maxLight = 96;
        }

        // Lighter colors for a light background
        if(darkBackground) {
            this.minLight = 67;
            this.maxLight = 67;
        }

        if((darkBackground || lightBackground) && light < this.minLight)
            light = this.minLight;


        if((darkBackground || lightBackground) && light > this.maxLight)
            light = this.maxLight;

        // Set hue
        this.hue = hue;
        this.sat = sat;
        this.light = light;

        this.hsl = 'hsl(' + this.hue + ', ' + this.sat + '%, ' + this.light + '%)';
    };

    changeHue(hue, rotate) {
        return hue + rotate > this.maxHue ?
        (hue + rotate) - this.maxHue : hue + rotate;
    };

    changeLight = function(light) {
        return light + this.scaleLight > this.maxLight ? this.maxLight : light + this.scaleLight;
    }

    isDarkColor() {
        return this.light < 15
    }

    isLightColor() {
        return this.light > 80
    }

    asHex() {
        return hslToHex(this.hue, this.sat, this.light)
    }

}

class PaletteGeneratorService {

      generatePalette(primary, coloredBackground=false) {

        var primHsl = hexToHsl(primary)
        
        var baseColor = new SColor(primHsl.h, primHsl.s, primHsl.l);

        var primDark = new SColor(baseColor.hue, baseColor.sat, baseColor.light / 2)
        var primDarker = new SColor(baseColor.hue, baseColor.sat / 2 , baseColor.light / 3)

        var buttonHover = new SColor(baseColor.hue, baseColor.sat, baseColor.light * 1.25, baseColor.isLightColor(), baseColor.isDarkColor())
        var buttonShadow = new SColor(baseColor.hue + 7, baseColor.sat - 3.5, baseColor.light + 3);
        var buttonActive = new SColor(baseColor.hue + 3, baseColor.sat, baseColor.light - 8);
        var powderblue = new SColor(baseColor.hue + 5.3, baseColor.sat - 10, baseColor.light * 1.5 + 3, true, baseColor.isDarkColor())
        var lightteal = new SColor(baseColor.hue - 4, baseColor.sat / 2 + 5.6, baseColor.light * 1.6 + 3, false, true)
        var verylightteal = new SColor(baseColor.hue - 6, baseColor.sat / 2, baseColor.light * 2 - 2, true)

        return {
            blue: baseColor.asHex(),
            darkblue: primDark.asHex(),
            darkskyblue: primDarker.asHex(),
            buttonhover: buttonHover.asHex(),
            buttonshadow: buttonShadow.asHex() + '99',
            buttonactive: buttonActive.asHex(),
            hamburger: !coloredBackground ? baseColor.asHex() : '#ffffff',
            lightteal: lightteal.asHex(),
            powderblue: powderblue.asHex(),
            verylightteal: verylightteal.asHex(),
        }
      };
}

export default new PaletteGeneratorService()