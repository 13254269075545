import React, { useContext, useEffect, useReducer } from "react"
import { Action } from "../models/Action";
import { SessionContextStore } from "./SessionContext";

export const ACTIONS = {
    PROFILE_SAVE: "PROFILE_SAVE",
    SAVE_ADDRESS: 'SAVE_ADDRESS',
    CHANGE_NUMBER_OF_DRONES_OPERATE:"CHANGE_NUMBER_OF_DRONES_OPERATE",
    SAVE_USER_INFO: 'SAVE_USER_INFO',
    SAVE_INSURANCE_HISTORY: 'SAVE_INSURANCE_HISTORY',
    SAVE_COMPANY: 'SAVE_COMPANY',
    SAVE_AGENT_INFO: "SAVE_AGENT_INFO",
    SAVE_FLEET: "SAVE_FLEET",
    RESET: 'RESET',
    SET_ERROR: 'SET_ERROR'
}

const defaultState = {
    dispatch: (action: Action) => {},
    address: {},
    number_of_drones_operate:0,
    user_info: {},
    company: {},
    insurance_history: {},
    fleet: {},
    agent_info: {},
    address_saved: false,
    user_info_saved: false,
    insurance_history_saved: false,
    company_saved: false,
    agent_info_saved: false,
    fleet_saved: false,
    hasError: null
}

const reducer = (state, action: Action) => {
    let newState = state
    switch (action.type) {
        case ACTIONS.SAVE_ADDRESS:
            newState = {...state, address: action.data, address_saved: true}
            break
        case ACTIONS.CHANGE_NUMBER_OF_DRONES_OPERATE:
            newState = {...state, number_of_drones_operate: action.data}
            break
        case ACTIONS.SAVE_USER_INFO:
            newState = {...state, user_info: action.data, user_info_saved: true}
            break
        case ACTIONS.SAVE_INSURANCE_HISTORY:
            newState = {...state, insurance_history: action.data, insurance_history_saved: true}
            break
        case ACTIONS.SAVE_COMPANY:
            newState = {...state, company: action.data, company_saved: true}
            break
        case ACTIONS.RESET:
            newState = {...state, insurance_history_saved: false, user_info_saved: false, address_saved: false}
            break
        case ACTIONS.SAVE_AGENT_INFO:
            newState = {...state, agent_info: action.data, agent_info_saved: true}
            break
        case ACTIONS.SAVE_FLEET:
            newState = {...state, fleet_saved: true, fleet: {file: action.data}}
            break
        case ACTIONS.SET_ERROR:
            newState = {...state, hasError: action.data}
            break
        
      default: 
        newState = state;
        break
    }
    
    return newState
};


export const UserProfileContextStore = React.createContext(defaultState)

const UserProfileContext = (props) => {
    const [state, dispatch] = useReducer(reducer, defaultState)
    const sessionContext = useContext(SessionContextStore)
    const setError = (message) => {
        dispatch({type: ACTIONS.SET_ERROR, data: message})
    }

    useEffect(() => {
        if(!sessionContext.user.numberOfDroneUsersOperates) return
        dispatch({type: ACTIONS.CHANGE_NUMBER_OF_DRONES_OPERATE, data: sessionContext.user.numberOfDroneUsersOperates})
    },[sessionContext.user.numberOfDroneUsersOperates])


    return (<UserProfileContextStore.Provider value={{ ...state, dispatch, setError }}>
            {props.children}
        </UserProfileContextStore.Provider>)   
}

export default UserProfileContext



