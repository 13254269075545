import QuoteModel, { QuoteStateModel } from "../insurance-models/QuoteModel"


export default class UserProfileModel {
    firstName = ''
    lastName = ''
    operator = ''
    address1 = ''
    address2 = ''
    userId: ?Number = -1
    avgFlightScore: ?Number = undefined
    totalCoverageHours: ?Number = 0
    totalScoredFlights: ?Number = 0
    totalDiscount: ?Number = 1
    userRank = { title: 'Silver'}
    discountEligibility: Boolean
    numberOfDroneUsersOperates: Number
    email: String
    city = ''
    state = ''
    zipCode = ''
    dateOfBirth: Date = undefined
    creditBalance: Number = 0
    commercial = false
    droneLoss: ?boolean = false
    hadIncidentsOrLosses: ?boolean = undefined
    hadFelony: ?boolean = undefined
    company = ""
    droneLossDescription: ?string = ""
    felonyDescription: ?string = ""
    incidentsOrLossesDescription: ?string = ""
    incidentsOrLossesValue: ?Number = undefined
    incidentsOrLossesDate: Date = undefined
    incidentsOrLossesMoreClaims: ?boolean = undefined
    declerationAgreementDescription: ?string = ""
    declerationAgreement = undefined
    overflightsOfPeople: ?Boolean = undefined
    overflightsOfPeopleDescription: ?string = ""
    eventsWithLargeNumberOfPeople: ?Boolean = undefined
    eventsWithLargeNumberOfPeopleDeclarationDescription: ?string = ""    
    eventsWithLargeNumberOfPeopleDeclaration: ?Boolean = undefined  
    commercialOperationsByRpacPilot: ?Boolean = undefined
    commercialOperationsByRpacPilotDescription: ?string = ""
    declinations= []
    hasCreditCard = false
    profilePicture = ''
    profilePictureFile = null
    phoneNumber = ''
    flightPurpose = 'default'
    latestQuote:? QuoteModel = undefined
    latestState:? QuoteStateModel = undefined
    status:?any = null
    claimCount: Number = 0
    agentName = null
    agentEmail = null
    fleetSize = null
    fleetIntendedUse = null
    fleetDocumentName = null
    purchaseEligibility= true
    hullEligibility= true
    liabilityLimitAllow = 10000000

      hasData(){
        return !!this.address1;
      }
    
      static fullNameSplitter(fullName){
        if(!fullName){
          return ["", ""];
        }
        let nameParts = fullName.split(" ");
        if(nameParts.length == 0){
          return ["", ""];
        }
        if(nameParts.length == 1){
          return [nameParts[0], ""];
        }
        return [nameParts[0], nameParts.slice(1, nameParts.length).join(" ")];
      }
    
      static fullNameCreator(firstName, lastName){
        let trimedFirstName = firstName.trim();
        let trimedLastName = lastName.trim();
        if(trimedFirstName == "" && trimedLastName == ""){
          return "";
        }
        if(trimedFirstName == ""){
          return trimedLastName;
        }
        if(trimedLastName == ""){
          return trimedFirstName;
        }
        // trimedFirstName = trimedFirstName.chatAt(0).toUpperCase() + trimedFirstName.slice(1)
        return trimedFirstName + " " + trimedLastName;
      }
}