import React from "react"
import { Route, Redirect, useLocation } from "react-router-dom";
import { SessionService } from "../../../services/SessionService";


export const TokenProtectedRoute = ({children, ...rest}) => {
    const location = useLocation()

    const query = new URLSearchParams(location.search);

    return (
        <Route
          {...rest}
          render={({ location }) =>
            query.has('token') ? 
              children 
              : 
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }}
              />
          }
        />
      );
}