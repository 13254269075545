/* eslint-disable flowtype/no-types-missing-file-annotation */

export class PhysicalDamageExtensionOffer {
    id: String
    price: Number
    taxes: Number
    taxesMultiplier: Number
    finalPrice: Number
    priceWithTaxes: Number
    totalDiscount: Number
    avgFlightScoreMultiplier: Number
    totalCoverageHoursMultiplier: Number
    creditUtilization: Number
    finalCost: Number
    dronePremium: Number
    equipmentPremium: Number
  }
  