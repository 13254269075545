import React from 'react'
import { Typography, Grid, Container, Button } from '@material-ui/core';
import './Congratulations.scss'
import { OrangeButton } from '../Core/Buttons/OrangeButton';

    

export const UUD = () => {
    const buttonStyle = {
        backgroundColor: '#F67347',
        color: 'white',
      }

    const openJotForm = () => {
        window.open('https://form.jotform.com/240441870523047', '_blank');
    }
    return (
            <div className="underwriter-bg-img">
                <Typography class="underwriter-main-title"><span className='super-light-blue margin-bottom-large' style={{fontWeight: 'bolder'}}>Underwriter approval <br/> required</span></Typography>
                <div className="declination-text">
                <Typography className='white bold'>Your policy is under review</Typography>
                <br/>
                <Typography variant='body1' className='white'>To complete the process quickly, please provide the additional information by clicking the button below.</Typography>
                <br/>
                <OrangeButton  onClick={openJotForm} >
                Submit Additional Information  {'>>'}
                </OrangeButton>
                </div>
                <div className="declination-text">
                <Typography variant='body1' className='white margin-bottom-medium'>If you have any questions feel free to call our support team <br/>888-364-5033</Typography>
                </div>
            </div>
    )

}

