import ApiService, { HTTPMethod } from "./api.core";
import { buildQueryParamsUrl } from '../services/utils';

class GeneralApi {
    async getIntendedUses()
    {
        return await ApiService.fetch('general/uses', HTTPMethod.GET);
    }

    async getLiabilitylimitOptions()
    {
        return await ApiService.fetch('general/getLiabilityOptions', HTTPMethod.GET);
    }
}

export default new GeneralApi()