import React from "react"
import { Route, Redirect, useLocation } from "react-router-dom";
import { SessionService } from "../../../services/SessionService";


export const LoginProtectedRoute = ({children, redirectTo="/login", ...rest}) => {
  return (
      <Route
        {...rest}
        render={({ location }) =>
          SessionService.isLoggedIn() ? 
            children 
            : 
            <Redirect
              to={{
                pathname: redirectTo,
                state: { from: location },
              }}
            />
        }
      />
  );
}