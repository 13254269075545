import React from 'react'
import { Typography, Grid, Container } from '@material-ui/core';
import './Congratulations.scss'
import { liabilityLimitPretty } from '../../services/utils';

export const Declination = (type) => {
    const decliantion_type = type.location.state.property_name
    const maximum_allowed_limit = liabilityLimitPretty(type.location.state.maximum_allowed_limit)
    return ( 
            <div className="underwriter-bg-img">
                <Typography class="underwriter-main-title">
                <span className='super-light-blue margin-bottom-large' style={{fontWeight: 'bolder'}}>
                {decliantion_type === 'LimitLiabilityDetails' ? 
                    <div>
                    Sorry, we were unable
                    <br/>
                    to offer Liability coverage
                    <br/>
                    at the requested limit.
                    </div> 
                    : 
                    decliantion_type === 'LimitHullDetails' ?
                    <div>
                    Sorry, we were unable
                    <br/>
                    to offer hull coverage
                    <br/> 
                    at this time.
                    </div>
                    :
                    <div>
                    Sorry, we were unable
                    <br/>
                    to offer coverage at this time.
                    </div>
                }
                </span> </Typography>
                <div className="declination-text">
                <br/>

                <Typography variant='body1' className='white'>
                {decliantion_type === 'LimitLiabilityDetails' ? 
                    <div>
                    Thank you for your interest in our insurance services.
                    <br/>
                    Unfortunately, we are unable to offer coverage at the requested liability limit.
                    <br/>
                    We invite you to revise your application to the maximum allowed limit of {maximum_allowed_limit},
                    <br/>
                    which aligns with our coverage capabilities.
                    </div>
                    : 
                    decliantion_type === 'LimitHullDetails' ?
                    <div>
                    Thank you for applying for insurance coverage with us.
                    <br/>
                    Your application for liability insurance is approved.
                    <br/>
                    However, hull insurance cannot be included in this application.
                    <br/>
                    We encourage you to revise your application accordingly and try again.
                    <br/>
                    For any guidance or assistance, please contact us.
                    </div>
                    :
                    <div>
                    Thank you for applying for insurance coverage with us.
                    <br/>
                    Unfortunately, after reviewing your application,
                    <br/>
                    we have determined that we cannot offer you a policy at this time.
                    <br/>
                    This decision was made based on a comprehensive evaluation of all relevant factors.
                    <br/>
                    We understand this may be disappointing,
                    <br/>
                    If you have any questions or wish to explore other options,
                    <br/>
                    please do not hesitate to contact us at 888-364-5033.
                    </div>
                }
                </Typography>
                </div>
            </div>
                  
    )

}

