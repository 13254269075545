import { THEMES } from "../theme/Theme";
import * as firebase from "firebase/app";
import { CountryBasedFirebaseInitialize } from "../models/services/CountryBasedFirebaseInitialize";
import { countryBasedConfig } from "./FirebaseService";


class FirebaseDatabaseService {

    constructor() {
        this.app = countryBasedConfig.app
    }

    async getSnapshotData(collection, documentName) {
        let snapshot = await firebase.database().ref(`/${collection}/${documentName}`).once('value')
        return snapshot.val()
    }

}

export default new FirebaseDatabaseService()