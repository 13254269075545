import ApiService, { HTTPMethod } from "./api.core";
import { EquipmentType } from "../models/hull-models/EuipmentTypes";
import { Transformer } from "../models/Transformer";
import { SessionService } from "../services/SessionService";


export class HullApi {

    static async getEquipmentTypes(): EquipmentType[] {
        let res = await ApiService.fetch("users/me/equipment/types");
        let lst = []
        if(res.ok) {
            res.data.types.forEach(element => {
                lst.push(Transformer.initializeFromData(new EquipmentType(), element))
            });
        }
        return lst
    }

    static async saveDrones(drones) {
        return ApiService.fetch("users/me/drones", HTTPMethod.POST, {
            drones: drones
        });
    }

    static async saveEquipment(equipment) {
        return ApiService.fetch("users/me/equipment", HTTPMethod.POST, {
            equipment: equipment
        });
    }

    static async getUserEquipment() {
        return ApiService.fetch("users/me/equipment");
    }

    static async getUserDrones() {
        let res = await ApiService.fetch("users/me/drones");
        if(res.data && res.data.drones) {
            res.data.drones = res.data.drones.map(d => {
                d.type = 'drone'
                return d
            })
        }
        return res
    }

    static async uploadDroneImage(dronePhoto) {
        const form = new FormData();
        form.append("file", dronePhoto)
        let res = await ApiService.fetch("users/me/drones/images", HTTPMethod.POST, form, false);
        return res;
      }
}