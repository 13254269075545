import { SessionService } from "./SessionService";
import StorageService from "./StorageService";

export class PurchaseFlowSessionService {
    static getItem(key){
        if (SessionService.isBroker() && SessionService.getStoredBrokerOnBehalfToken()) {
            return sessionStorage.getItem(key);
        } else{
            return StorageService.getItem(key)
        }
    }

    static setItem(key, item){
        if (SessionService.isBroker() && SessionService.getStoredBrokerOnBehalfToken()) {
            return sessionStorage.setItem(key, item);
        } else{
            return StorageService.setItem(key, item)
        }
    }

    static removeItem(key){
        if (SessionService.isBroker() && SessionService.getStoredBrokerOnBehalfToken()) {
            return sessionStorage.removeItem(key);
        } else{
            return StorageService.removeItem(key)
        }
    }
}
